<template lang="html">
  <h1>Faktura</h1>
  <select
    name="fakturaSelect"
    id="type"
    v-model="faktura"
    @change="onChangeFaktura($event)"
  >
    <option v-for="fakt in fakture" v-bind:key="fakt">{{ fakt }}</option>
  </select>

  <div class="faktura" id="faktura">
    <div class="peti">
      <div class="" v-if="trenutnaFakturaBroj == 0">
        <p><b>KEY SOLUTIONS CONSALTING PR</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 1 || trenutnaFakturaBroj == 6">
        <p><b>KEY SOLUTIONS 1 ZORICA ILIĆ PR</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 2">
        <p>
          <b
            >IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR
            BEOGRAD</b
          >
        </p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 3">
        <p><b>KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 4">
        <p><b>KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 5">
        <p><b>KEY SOLUTIONS CONSALTING PR</b></p>
      </div>

      <div class="" v-if="trenutnaFakturaBroj == 0">
        <p style="float:right"><b>PIB: 106951145</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 1 || trenutnaFakturaBroj == 6">
        <p style="float:right"><b>PIB: 110274733</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 2">
        <p style="float:right"><b>PIB: 112593631</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 3">
        <p style="float:right"><b>PIB: 112420198</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 4">
        <p style="float:right"><b>PIB: 112420198</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 5">
        <p style="float:right"><b>PIB: 106951145</b></p>
      </div>
    </div>

    <div class="container">
      <div class="" v-if="trenutnaFakturaBroj == 0">
        <p>Beograd, Nedeljka Gvozdenovića 12/2</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 1 || trenutnaFakturaBroj == 6">
        <p>Beograd, Gandijeva 167</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 2">
        <p>Beograd, Nedeljka Gvozdenovića 16a</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 3">
        <p>Beograd, Vladimira Popovića 28/12a</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 4">
        <p>Beograd, Vladimira Popovića 28/12a</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 5">
        <p>Beograd, Nedeljka Gvozdenovića 12/2</p>
      </div>

      <div class="" v-if="trenutnaFakturaBroj == 0">
        <p style="float:right">Matični broj: 62393211</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 1 || trenutnaFakturaBroj == 6">
        <p style="float:right">Matični broj: 64777335</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 2">
        <p style="float:right">Matični broj: 66216195</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 3">
        <p style="float:right">Matični broj: 66090230</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 4">
        <p style="float:right">Matični broj: 66090230</p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 5">
        <p style="float:right">Matični broj: 62393211</p>
      </div>

      <div
        class=""
        v-if="
          trenutnaFakturaBroj == 0 ||
            trenutnaFakturaBroj == 1 ||
            trenutnaFakturaBroj == 2 ||
            trenutnaFakturaBroj == 5 ||
            trenutnaFakturaBroj == 6
        "
      >
        <p>Telefon (011) 4084678</p>
      </div>

      <div class="" v-if="trenutnaFakturaBroj == 3 || trenutnaFakturaBroj == 4">
        <p>Telefon: +381 (0)65 8502322</p>
      </div>

      <div class=""></div>

      <div
        class=""
        v-if="
          trenutnaFakturaBroj == 0 ||
            trenutnaFakturaBroj == 1 ||
            trenutnaFakturaBroj == 2 ||
            trenutnaFakturaBroj == 3
        "
      >
        <p><b>Tekući račun:</b></p>
      </div>
      <div
        class=""
        v-if="
          trenutnaFakturaBroj == 4 ||
            trenutnaFakturaBroj == 5 ||
            trenutnaFakturaBroj == 6
        "
      >
        <p><b>Devizni račun:</b></p>
      </div>

      <div class="">
        <p style="float:right">Šifra delatnosti: 7022</p>
      </div>

      <div class="" v-if="trenutnaFakturaBroj == 0">
        <p><b>160-349895-28 Banca Intesa</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 1">
        <p><b>160-491781-06 Banca Intesa</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 2">
        <p><b>160600000122415310 Banca Intesa</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 3">
        <p><b>160600000103471016 Banca Intesa</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 4">
        <p><b>160600000120909482 Banca Intesa</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 5">
        <p><b>00-514-0026299.7 Banca Intesa</b></p>
      </div>
      <div class="" v-if="trenutnaFakturaBroj == 6">
        <p><b>160600000179528716 Banca Intesa</b></p>
      </div>
    </div>

    <div class="drugi">
      <div class="peti">
        <p>Platilac:</p>
        <input type="text" v-model="platilac" />
      </div>
      <div class="peti">
        <p>PIB:</p>
        <input type="text" v-model="pib" />
      </div>
      <div class="peti">
        <p>MB:</p>
        <input type="text" v-model="mb" />
      </div>
      <div class="peti">
        <p>Ime kompanije:</p>
        <input type="text" v-model="imeKompanije" />
      </div>
      <div class="peti">
        <p>Adresa:</p>
        <input type="text" v-model="adresa" />
      </div>
      <div class="peti">
        <p>Država:</p>
        <input type="text" v-model="drzava" />
      </div>
    </div>

    <!-- <div style="margin:2rem 0">
      <b>ODABRANA OPCIJA</b>:
      <select v-model="odabranaOpcija">
        <option value="Račun">Račun</option>
        <option value="Predračun">Predračun</option>
      </select>
    </div> -->

    <!-- <div v-if="odabranaOpcija == 'Predračun'" class="">
      <p><b>PREDRAČUN BROJ:</b></p>
      <input type="text" v-model="racunBroj" />
      <input type="text" v-model="racunGodina" />
    </div> -->

    <!-- <div v-else class="">
      <p><b>RAČUN BROJ:</b></p>
      <input type="text" v-model="racunBroj" />
      <input type="text" v-model="racunGodina" />
    </div> -->

        <div class="">
      <p><b>RAČUN BROJ:</b></p>
      <input type="text" v-model="racunBroj" />
      <input type="text" v-model="racunGodina" />
    </div>

    <div class="treci">
      <div class=""></div>
      <div class="">
        <p style="float:right">Mesto izdavanja: Beograd</p>
      </div>
      <div class=""></div>
      <div class="peti">
        <p style="float:right">Datum izdavanja:</p>
        <input type="text" v-model="datumIzdavanja" />
      </div>
      <div class=""></div>
      <div class="peti">
        <p style="float:right"><b>Valuta: </b></p>
        <input type="text" v-model="valuta" />
      </div>
      <div class=""></div>
      <div class="peti">
        <p style="float:right"><b>Datum prometa: </b></p>
        <input type="text" v-model="datumPrometa" />
      </div>
    </div>

    <div class="cetvrti">
      <div class="">
        <p>Naziv usluge</p>
      </div>
      <div class="">
        <p style="float:right">Cena usluge</p>
      </div>
    </div>

    <div class="cetvrti">
      <div class="" style="height:2em;">
        <input type="text" style="width:100%;" v-model="nazivUsluge" />
      </div>
      <div class="">
        <input style="float:right" type="text" v-model="cenaUsluge" />
      </div>
    </div>

    <div class="peti">
      <div class=""></div>
      <div class="peti">
        <p><b>Iznos:</b></p>
        <input style="float:right" type="text" v-model="iznos" />
      </div>
    </div>

    <div class="">
      <div class="peti">
        <p>Slovima:</p>
        <input style="float:right" type="text" v-model="slovima" />
      </div>

      <p v-if="trenutnaFakturaBroj == 0">
        <b
          >Uplatu izvršiti na račun kod Banca Intesa AD Beograd:
          160-349895-28</b
        >
      </p>
      <p v-if="trenutnaFakturaBroj == 1">
        <b
          >Uplatu izvršiti na račun kod Banca Intesa AD Beograd:
          160-491781-06</b
        >
      </p>
      <p v-if="trenutnaFakturaBroj == 2">
        <b
          >Uplatu izvršiti na račun kod Banca Intesa AD Beograd:
          160600000122415310</b
        >
      </p>
      <p v-if="trenutnaFakturaBroj == 3">
        <b
          >Uplatu izvršiti na račun kod Banca Intesa AD Beograd:
          160600000103471016</b
        >
      </p>
      <p v-if="trenutnaFakturaBroj == 4">
        <b
          >Uplatu izvršiti na račun kod Banca Intesa AD Beograd:
          160600000120909482</b
        >
      </p>
      <p v-if="trenutnaFakturaBroj == 5"><b></b></p>
      <p v-if="trenutnaFakturaBroj == 6">
        <b
          >Uplatu izvršiti na račun kod Banca Intesa AD Beograd:
          160600000179528716</b
        >
      </p>

      <p v-if="trenutnaFakturaBroj == 0">
        NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions consalting nije u sistemu
        PDV-a
      </p>
      <p v-if="trenutnaFakturaBroj == 1 || trenutnaFakturaBroj == 6">
        NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions 1 nije u sistemu PDV.
      </p>
      <p v-if="trenutnaFakturaBroj == 2">
        NAPOMENA O PORESKOM OSLOBOĐENJU: IVANA MLADENOVIĆ PR KONSULTANTSKE
        USLUGE KEY SOLUTIONS FOR HR BEOGRAD nije u sistemu PDV.
      </p>
      <p v-if="trenutnaFakturaBroj == 3">
        NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions For Your Business Nataša
        Vasić PR nije u sistemu PDV.
      </p>
      <p v-if="trenutnaFakturaBroj == 4">
        NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions For Your Business Nataša
        Vasić PR nije u sistemu PDV.
      </p>
      <p v-if="trenutnaFakturaBroj == 5">
        NAPOMENA O PORESKOM OSLOBOĐENJU: Key Solutions Consalting nije u sistemu
        PDV-a
      </p>
    </div>
    <p v-if="showNotification">
      Račun je punovazan bez pečata i potpisa u skladu sa Članom 9 st. 1 i 2
      Zakona o računovodstvu i Objašnjenju Ministarstva finansija br.
      401-00-4169/2017-16 od 12.12.2017 godine.
    </p>
    <div class="footer">
      <div class=""></div>
      <div class="">
        <p style="text-align:center;">M.P.</p>
      </div>

      <div
        class=""
        v-if="
          trenutnaFakturaBroj == 0 ||
            trenutnaFakturaBroj == 2 ||
            trenutnaFakturaBroj == 5
        "
      >
        <p style="float:right">Fakturisao: Milenko Mladenović</p>
      </div>

      <div class="" v-if="trenutnaFakturaBroj == 3 || trenutnaFakturaBroj == 4">
        <p style="float:right">Fakturisao: Nataša Vasić</p>
      </div>

      <div class="" v-if="trenutnaFakturaBroj == 1 || trenutnaFakturaBroj == 6">
        <p style="float:right">Fakturisao: Zorica Ilic</p>
      </div>
    </div>
  </div>

  <button type="button" name="button" @click="submit">Dodaj Fakturu</button>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import html2canvas from "html2canvas";
// eslint-disable-next-line no-unused-vars
import jsPDF from "jspdf";
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      faktura: "KEY SOLUTIONS CONSALTING PR",
      fakture: [],
      trenutnaFakturaBroj: 0,
      platilac: "",
      pib: "",
      mb: "",
      imeKompanije: "",
      adresa: "",
      drzava: "",
      datumIzdavanja: "",
      datumPrometa: "",
      valuta: "",
      nazivUsluge: "",
      cenaUsluge: "",
      iznos: "",
      slovima: "",
      racunBroj: "",
      racunGodina: "",
      // odabranaOpcija: "Račun",
    };
  },
  computed: {
    showNotification() {
      return this.faktura === "KEY SOLUTIONS CONSALTING PR"
        ? false
        : this.faktura === "KEY SOLUTIONS CONSALTING (DEVIZNI)"
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions(["submitFaktura"]),
    submit() {
      let data = {
        type: this.trenutnaFakturaBroj,
        platilac: this.platilac,
        pib: this.pib,
        mib: this.mb,
        imeKompanije: this.imeKompanije,
        adresa: this.adresa,
        drzava: this.drzava,
        datumIzdavanja: this.datumIzdavanja,
        datumPrometa: this.datumPrometa,
        valuta: this.valuta,
        nazivUsluge: this.nazivUsluge,
        cenaUsluge: this.cenaUsluge,
        iznos: this.iznos,
        slovima: this.slovima,
        racunBroj: this.racunBroj,
        racunGodina: this.racunGodina,
        // isFaktura: this.odabranaOpcija === "Račun" ? true : false,
      };
      console.log(data);
      this.submitFaktura(data);
    },
    onChangeFaktura(event) {
      if (event.target.value == "KEY SOLUTIONS CONSALTING PR") {
        this.trenutnaFakturaBroj = 0;
      }
      if (event.target.value == "KEY SOLUTIONS 1 ZORICA ILIĆ PR") {
        this.trenutnaFakturaBroj = 1;
      }
      if (
        event.target.value ==
        "IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR BEOGRAD"
      ) {
        this.trenutnaFakturaBroj = 2;
      }
      if (
        event.target.value == "KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR"
      ) {
        this.trenutnaFakturaBroj = 3;
      }
      if (
        event.target.value ==
        "KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR (DEVIZNI)"
      ) {
        this.trenutnaFakturaBroj = 4;
      }
      if (event.target.value == "KEY SOLUTIONS CONSALTING PR (DEVIZNI)") {
        this.trenutnaFakturaBroj = 5;
      }
      if (event.target.value == "KEY SOLUTIONS 1 ZORICA ILIĆ PR (DEVIZNI)") {
        this.trenutnaFakturaBroj = 6;
      }
    },
    makePDF() {
      window.html2canvas = html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("p", "pt", "a3");
      doc.html(document.querySelector("#faktura"), {
        callback: function(pdf) {
          pdf.save("faktura.pdf");
        },
      });
    },
  },
  created() {
    let fk = "KEY SOLUTIONS CONSALTING PR";
    this.fakture.push(fk);
    fk = "KEY SOLUTIONS 1 ZORICA ILIĆ PR";
    this.fakture.push(fk);
    fk = "KEY SOLUTIONS 1 ZORICA ILIĆ PR (DEVIZNI)";
    this.fakture.push(fk);
    fk =
      "IVANA MLADENOVIĆ PR KONSULTANTSKE USLUGE KEY SOLUTIONS FOR HR BEOGRAD";
    this.fakture.push(fk);
    fk = "KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR";
    this.fakture.push(fk);
    fk = "KEY SOLUTIONS FOR YOUR BUSINESS NATAŠA VASIĆ PR (DEVIZNI)";
    this.fakture.push(fk);
    fk = "KEY SOLUTIONS CONSALTING PR (DEVIZNI)";
    this.fakture.push(fk);

    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let dateText = day + "." + month + "." + year + ".";

    this.datumIzdavanja = dateText;
  },
};
</script>

<style lang="css" scoped>
.faktura {
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 10px;

  height: 1190px;
  width: 840px;
}
.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". .";
}
.stoposto {
  width: 100%;
}

.drugi {
  border-style: solid;
  border-color: black;
  border-width: thin;
  border-radius: 15px;
  padding: 10px;
  width: 60%;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "."
    "."
    "."
    "."
    ".";
}

.treci {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". .";
}
.cetvrti {
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 5px;
  margin-bottom: 5px;

  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
.peti {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}
.footer {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . .";
}
</style>
